import React, { useEffect, useState } from 'react'
import {Button, Card, CardActions, CardContent} from "@material-ui/core";
import {ThumbsUp} from "react-feather";

export default function LikeButton ({likes, wishid, updateLike, liked}) {
    const [like, setLike] = useState(liked)

    const handleToggle = () => {
        const newLikeSetting = !like
        setLike(newLikeSetting)
        updateLike(newLikeSetting, wishid)
    }

    return (
      <>
           <Button variant={like ? "contained" : "outlined"} color="primary" startIcon={<ThumbsUp />} onClick={handleToggle}>
                {likes}
           </Button>
      </>
    )
}


