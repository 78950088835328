import React, { useEffect, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import Actions from './Actions'
import Popover from '@material-ui/core/Popover'
import useMissionPointSvgGenerator from '../../../hooks/useMissionPointSvgGenerator'
import useUser from '../../../hooks/useUser'
import People from './People'
import CloseIcon from '@material-ui/icons/Close'
import scrollTo from 'gatsby-plugin-smoothscroll'
import useExternalLinkHandler from '../../../hooks/useExternalLinkHandler'

function Missions ({subPages: { allWpSdg: { nodes } }, activeMission }) {
  const [anchorMissionInfoEl, setAnchorMissionInfoEl] = useState(null)
  const [currentOpenMissionInfo, setCurrentOpenMissionInfo] = useState(null)
  const generateMissionPointIcons = useMissionPointSvgGenerator()
  const { user } = useUser()
  const { showExternalLinkConfirmation } = useExternalLinkHandler()

  const onMissionInfoClick = (event, mission) => {
    setCurrentOpenMissionInfo(mission)
    setAnchorMissionInfoEl(event.currentTarget)
  }
  const handleMissionInfoClose = () => {
    setCurrentOpenMissionInfo(null)
    setAnchorMissionInfoEl(null)
  }

  const [expandedAccordion, setExpandedAccordion] = useState(activeMission)
  const handleChange = panel => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : false)
  }

  const missions = nodes[0].wpChildren.nodes

  missions.sort((a, b) => {
    return a.differencesFor.nodes[0].DifferencesMeta.order - b.differencesFor.nodes[0].DifferencesMeta.order
  })

  useEffect(() => {
      setTimeout(() => {
        scrollTo(window?.location?.hash)
      }, 200)
  }, [])

// we are assuming there will always be one mission with one difference
  const jsx = missions.map(mission => {

    const checkIfMissionIsDone = () => {
      if (!user?.finishedTasks) return false

      return (
        undefined !==
        user?.finishedTasks.find(task => task.databaseId === mission.databaseId)
      )
    }

    const missionPointIcons = generateMissionPointIcons(mission.SdgMeta.points, checkIfMissionIsDone())

    const category = mission.differencesFor?.nodes[0]

    return (
      <div key={mission.id} className="mission">
        <div className="people-container">
          <div className="anchor" id={`mission_${mission?.databaseId}`}/>
          <People points={mission.SdgMeta.points}/>
        </div>
        <div className="mission-category-and-points">
          <h3 key={category.id}>Wandel für {category.name}</h3>
          {missionPointIcons}
        </div>
        <Accordion
          key={mission.id}
          expanded={expandedAccordion === mission.id}
          onChange={handleChange(mission.id)}
          className="mission-accordion"
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon/>} id={mission.id}>
            <Typography className="mission-accordion-summary">
               {mission.title} {/*isFavorite(mission) ? <span style={{color: 'grey'}}>&#11089;</span> : '' */}
            </Typography>

          </AccordionSummary>
          <AccordionDetails className="mission-accordion-details">
            <div
              className="mission-accordion-html"
              onClick={showExternalLinkConfirmation}
              dangerouslySetInnerHTML={{
                __html: mission.content,
              }}
            />
            <Popover
              open={currentOpenMissionInfo?.id === mission.id}
              anchorEl={anchorMissionInfoEl}
              onClose={handleMissionInfoClose}
              PaperProps={{
                style: { width: '100%', padding: 10 },
              }}
            >
              <CloseIcon onClick={handleMissionInfoClose} style={{ float: 'right', cursor: 'pointer' }}/>
              <h2>Hintergrund</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: mission.SdgMetaAfterContent?.missionBackground,
                }}
              />
            </Popover>
            <Actions mission={mission} onMissionInfoClick={onMissionInfoClick}/>
          </AccordionDetails>
        </Accordion>
      </div>
    )
  })

  return <div className="missions">{jsx}</div>
}

export default Missions
