import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import DialogContentText from '@material-ui/core/DialogContentText'
import Button from '@material-ui/core/Button'
import useGameConfig from '../../../hooks/useGameConfig'

function CongratulationsModal ({ open, handleClose, earnedPoints }) {
  const { gameConfig: { signedInConfig } } = useGameConfig()
  const { texts: { notificationTexts } } = useGameConfig()

  const text = earnedPoints > 1
    ? signedInConfig.sdgTaskFinishedCongratulationPlural
    : signedInConfig.sdgTaskFinishedCongratulationSingular

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="congrats-dialog-title"
      aria-describedby="congrats-dialog-description"
    >
      <DialogTitle id="congrats-dialog-title">{signedInConfig.sdgTaskFinishedCongratulationTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="congrats-dialog-description"
          dangerouslySetInnerHTML={{ __html: text.replace('${earnedPoints}', earnedPoints) }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          {notificationTexts.close}
        </Button>
      </DialogActions>
    </Dialog>)
}

export default CongratulationsModal