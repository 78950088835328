import React, { useState } from 'react'
import { ButtonGroup, Button } from '@material-ui/core'
import StarIcon from '@material-ui/icons/Star'
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined'
import useUser from '../../../hooks/useUser'
import useIsClient from '../../../hooks/useIsClient'
import Tooltip from '@material-ui/core/Tooltip'

function ActionsWishes ({ wish }) {
  const { user, addFavoriteWish, removeFavoriteWish } = useUser()
  const favoriteDefault = () => {
    if (!user?.wishes) return false

    return (
      undefined !== user?.wishes.find(favorite_wish => favorite_wish.databaseId === wish.databaseId)
    )
  }
  const [favorite, setFavorite] = useState(favoriteDefault())

  const { isClient } = useIsClient()
  if (!isClient) return null

  const handleMarkAsFavorite = () => {

    console.log("Test")

    const newFavoriteSetting = !favorite
    setFavorite(newFavoriteSetting)

    console.log("favorite", favorite)

    if (newFavoriteSetting) {
      addFavoriteWish(wish)
    } else {
      removeFavoriteWish(wish)
    }
  }

  return (
    <div className="mission-accordion-buttons">
      <ButtonGroup>
        <Tooltip title="Aufgabe merken?">
          <Button aria-label="TuDu" onClick={() => handleMarkAsFavorite()}>
            {favorite ? (
              <StarIcon className="favorite-icon filled"/>
            ) : (
              <StarBorderOutlinedIcon className="favorite-icon"/>
            )}
          </Button>
        </Tooltip>
      </ButtonGroup>
    </div>
  )
}

export default ActionsWishes
