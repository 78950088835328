import React, { useEffect, useState } from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl, FormHelperText, Input, InputLabel, TextField
} from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import {useMutation} from "@apollo/client";
import {SEND_WISH_FORM} from "../graphql/queries";


export default function WishForm ({sdg, userid, callback}) {
 const [open, setOpen] = React.useState(false);
 const [title, setTitle] = React.useState("");
 const [description, setDescription] = React.useState("");
 const [sendWishMutation] = useMutation(SEND_WISH_FORM)

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleSend = () => {
      sendWishMutation({
                variables: {
                    userid: userid,
                    title: title,
                    description: description,
                    sdg: sdg
                },
            }).then(r => {
                console.log("handleSend", r);
                callback(r.data.sendFormMutation.wishid);
            })

        handleClose()

  };

  const handleClose = () => {
    setOpen(false);
  };

   return (
       <>
            <div style={{marginBottom: '2em'}}><Button onClick={handleClickOpen} variant={"contained"} color={"primary"}>Wunsch einreichen</Button></div>
            <Dialog
                fullWidth
                maxWidth={'md'}
                onClose={handleClose} open={open}>
              <DialogTitle>Wunsch einreichen</DialogTitle>
              <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                        <div>
                            <FormControl fullWidth>
                              <InputLabel htmlFor="wish-titel">Titel</InputLabel>
                              <Input value={title}
                                     onChange={(event) => setTitle(event.target.value)}
                                     fullWidth
                                     id="wish-titel"
                                     aria-describedby="titel of wish" />
                            </FormControl>
                        </div>
                        <div>
                            <FormControl fullWidth>
                                <TextField
                                  value={description}
                                  onChange={(event) => setDescription(event.target.value)}
                                  fullWidth
                                  multiline
                                  id="wish-description"
                                  label="Beschreibung"
                                  variant="standard"
                                />
                            </FormControl>
                        </div>
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button onClick={handleClose}>Abbrechen</Button>
                  <Button onClick={handleSend} variant={"contained"} color={"primary"}>Abschicken</Button>
              </DialogActions>
            </Dialog>
           </>
    )
}

