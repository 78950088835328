import { Button, IconButton } from '@material-ui/core';
import React from 'react'

import TwitterIcon from '@material-ui/icons/Twitter'
import FacebookIcon from '@material-ui/icons/Facebook'
import MailIcon from '@material-ui/icons/Mail'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import TelegramIcon from '@material-ui/icons/Telegram'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import useSingleToast from '../hooks/useSingleToast'

import ShareIcon from '@material-ui/icons/Share'
import {Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions} from '@material-ui/core';
import {
    FacebookShareButton,
    TwitterShareButton,
    EmailShareButton,
    WhatsappShareButton, TelegramShareButton
} from 'react-share';
import Tooltip from '@material-ui/core/Tooltip'
import useGameConfig from "../hooks/useGameConfig";

export function ShareButtons({ url, title, description }) {
  const { addSingleToast } = useSingleToast()
  const [open, setOpen] = React.useState(false);
  const { texts: { others } } = useGameConfig()

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };


  return (
    <>
      <Tooltip title="Aufgabe teilen?">
        <Button onClick={handleOpen} style={{border: "1px solid rgba(0, 0, 0, 0.23)"}}>
          <ShareIcon className="mission-info-icon" />
        </Button>    
     </Tooltip>

      <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{others.sharemission} "{title}".</DialogTitle>
      <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="post-meta-share-icons">
                <FacebookShareButton url={url} quote={description}>
                    <FacebookIcon style={{fontSize: "3em", marginRight: "0.5em"}} />
                </FacebookShareButton>

                <TwitterShareButton url={url} title={description}>
                    <TwitterIcon style={{fontSize: "3em", marginRight: "0.5em"}} />
                </TwitterShareButton>

                <EmailShareButton url={url} subject={`Wirkel: ${title}`} body={description} separator={" "}>
                    <MailIcon style={{fontSize: "3em", marginRight: "0.5em"}} />
                </EmailShareButton>

                <WhatsappShareButton url={url} title={description}>
                    <WhatsAppIcon style={{fontSize: "3em", marginRight: "0.5em"}} />
                </WhatsappShareButton>

                <TelegramShareButton url={url} title={description}>
                    <TelegramIcon style={{fontSize: "3em", marginRight: "0.5em"}} />
                </TelegramShareButton>

                <CopyToClipboard text={description + " " + url} onCopy={() => {
                  addSingleToast(
                    <div>
                      <p>Kopiert!</p>
                    </div>,
                    { appearance: 'success' })
                }}>
                  <FileCopyIcon style={{fontSize: "3em", marginRight: "0.5em", cursor: "pointer"}}/>
                </CopyToClipboard>
            </div>
          </DialogContentText>
      </DialogContent>
      <DialogActions>
          <Button onClick={handleClose}>Schließen</Button>
      </DialogActions>
    </Dialog>
    </>
  );
}