import React from 'react'

function useMissionPointSvgGenerator () {

  function generateMissionPointIcons (amount, filled) {
    let allIcons = []
    while (amount--) {
      allIcons.push(
        <svg width="30px" height="30px" viewBox="0 0 4.1804 4.1804">
          <g transform="translate(-105.19 -67.328)">
            <g transform="matrix(.26458 0 0 .26458 28.649 -41.998)">
              <polygon points="289.3 429 305.1 429 305.1 413.2 289.3 413.2" fill="#fff"/>
              <clipPath>
                <use width="100%" height="100%" overflow="visible"/>
              </clipPath>
              <polygon points="289.3 429 305.1 429 305.1 413.2 289.3 413.2" fill="none" stroke="#cad6e0"/>
              <polygon points="293.3 425 301.2 425 301.2 417.2 293.3 417.2" fill={filled ? '#c7d400' : 'rgb(202,214,224)'}/>
            </g>
          </g>
        </svg>
      )
    }

    return (<div className="mission-points">{allIcons}</div>)
  }

  return generateMissionPointIcons
}

export default useMissionPointSvgGenerator