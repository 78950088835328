import React, { useEffect } from "react"
import Layout from "../components/layout"
import BottomNav from "../components/app/sdg/BottomNav"
import Grid from "@material-ui/core/Grid"
import Missions from "../components/app/sdg/Missions"
import { graphql, navigate, useStaticQuery } from "gatsby"
import Seo from "../components/seo"
import useUser from "../hooks/useUser"
import useExternalLinkHandler from "../hooks/useExternalLinkHandler"
import {
  GET_MY_TEAMS_AND_OTHER_TEAMS_LIGHT_QUERY,
  GET_WISHES,
} from "../graphql/queries"
import { useQuery } from "@apollo/client"
import Wishes from "../components/app/sdg/Wishes"

export default function Sdg(props) {
  const { showExternalLinkConfirmation } = useExternalLinkHandler()
  const { data, pageContext, location } = props
  // TODO: Workaround for now... Fix private routing for sub paths
  const isBrowser = typeof window !== "undefined"
  const { user, signedIn } = useUser()

  if (isBrowser && !signedIn) {
    // If we’re not logged in, redirect to the home page.
    navigate("/login")
    return null
  }
  const positionOfDash = pageContext.title.indexOf("–")
  const shortenedName = pageContext.title.substring(
    0,
    positionOfDash !== -1 ? positionOfDash : pageContext.title.length
  )

  return (
    <div className="sdg">
      <Seo title={pageContext.title} />
      <Layout className="sdg-layout" pageName={shortenedName}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className="space-top-20 space-bottom"
        >
          <Grid item xs={12} sm={4} md={4} lg={3}>
            {pageContext.featuredImage && (
              <a
                href={pageContext.externeUrl}
                onClick={e => showExternalLinkConfirmation(e, true)}
              >
                <img
                  src={pageContext.featuredImage.node.localFile.publicURL}
                  alt={pageContext.title}
                  className="sdg-featured-img"
                />
              </a>
            )}
          </Grid>
          <Grid item xs={12} lg={6} style={{ textAlign: "center" }}>
            <div
              style={{ padding: "1em" }}
              dangerouslySetInnerHTML={{ __html: pageContext.content }}
            />
          </Grid>
          <Grid item xs={12} lg={9}>
            <Missions subPages={data} activeMission={location?.state?.open} />
          </Grid>
          <Grid item xs={12} lg={9}>
            <Wishes sdgSlug={pageContext.slug} />
          </Grid>
        </Grid>
      </Layout>
      <BottomNav currentPostSlug={pageContext.slug} />
    </div>
  )
}

export const query = graphql`
  query getAllSdgsWithDifferences($slug: String) {
    allWpSdg(filter: { slug: { eq: $slug } }) {
      nodes {
        wpChildren {
          nodes {
            id
            ... on WpSdg {
              id
              differencesFor {
                nodes {
                  id
                  name
                  DifferencesMeta {
                    order
                  }
                }
              }
              title
              content
              databaseId
              SdgMetaAfterContent {
                missionBackground
              }
              parentDatabaseId
              slug
              SdgMeta {
                points
              }
            }
          }
        }
      }
    }
  }
`
