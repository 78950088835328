import React, { useEffect, useState } from "react"
import useUser from "../../../hooks/useUser"
import { useMutation, useQuery } from "@apollo/client"
import { GET_WISHES, UPDATE_WISH_MUTATION } from "../../../graphql/queries"
import { Card, CardActions, CardContent } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import LikeButton from "./LikeButton"
import useLocalStorage from "../../../hooks/useLocalStorage"
import produce from "immer"
import WishForm from "../../wish-form"
import useGameConfig from "../../../hooks/useGameConfig"
import ActionsWishes from "./ActionsWishes"

export default function Wishes({ sdgSlug }) {
  const { user, signedIn } = useUser()
  const [storedWishes, storeWishes] = useLocalStorage(
    process.env.WISHES_DATA_KEY,
    undefined
  )
  const {
    texts: { others },
  } = useGameConfig()

  const { data, setData, startPolling, stopPolling } = useQuery(GET_WISHES, {
    skip: !signedIn,
    ssr: false,
    notifyOnNetworkStatusChange: true,
    onCompleted: () => updateWishes(data?.wishes?.nodes),
  })

  const [updateWishMutation] = useMutation(UPDATE_WISH_MUTATION)

  const updateWishes = wishes => {
    // storeWishes([].concat(wishes).sort((a, b) => JSON.parse(b?.wishesmeta?.likes)?.length > JSON.parse(a?.wishesmeta?.likes)?.length ? 1 : -1))
    storeWishes(wishes)
  }

  useEffect(() => {
    updateWishes(data?.wishes?.nodes)

    if (signedIn) {
      startPolling(2000)
    } else {
      stopPolling()
    }
  }, [data])

  const updateLike = (like, wishid, handleUpdate = null) => {
    if (like) {
      addLike(wishid)
    } else {
      removeLike(wishid)
    }

    updateWishMutation({
      variables: {
        playerid: user.id,
        wishid: wishid,
      },
    }).then(r => {
      console.log("r", r)
    })
  }

  const addLike = wishid => {
    const updatedStoredWishes = storedWishes?.map(wish => {
      if (wish?.databaseId === wishid) {
        if (!wish?.wishesmeta?.likes?.includes(user.id)) {
          return produce(wish, draft => {
            let likes = JSON.parse(wish.wishesmeta.likes)
            likes.push(user.id)

            draft.wishesmeta = wish.wishesmeta
            draft.databaseId = wish.databaseId
            draft.wishesmeta.likes = JSON.stringify(likes)
            return draft
          })
        }
      }
      return wish
    })
    updateWishes(updatedStoredWishes)
  }

  const removeLike = wishid => {
    const updatedStoredWishes = storedWishes?.map(wish => {
      if (wish?.databaseId === wishid) {
        let likes = JSON.parse(wish.wishesmeta.likes)
        likes = likes.filter(function (like) {
          return like !== user.id
        })

        return produce(wish, draft => {
          draft.wishesmeta = wish.wishesmeta
          draft.databaseId = wish.databaseId
          draft.wishesmeta.likes = JSON.stringify(likes)
          return draft
        })
      }
      return wish
    })
    updateWishes(updatedStoredWishes)
  }

  const [anchorMissionInfoEl, setAnchorMissionInfoEl] = useState(null)
  const [currentOpenMissionInfo, setCurrentOpenMissionInfo] = useState(null)
  const onMissionInfoClick = (event, mission) => {
    setCurrentOpenMissionInfo(mission)
    setAnchorMissionInfoEl(event.currentTarget)
  }

  return (
    <>
      <h1 id="wishes">{others.wirkelwuensche}</h1>
      <WishForm
        sdg={sdgSlug}
        userid={user?.id}
        callback={wishid => addLike(wishid)}
      />
      {storedWishes?.map(wish => {
        return (
          wish?.wishesmeta?.sdg == sdgSlug &&
          wish?.wishesmeta?.visible === "true" && (
            <Card style={{ marginBottom: "1em" }} key={wish.databaseId}>
              <div className="anchor" id={`wish_${wish?.databaseId}`} />
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  {wish?.wishesmeta?.title}
                </Typography>
                <Typography variant="body2">
                  {wish?.wishesmeta?.description}
                </Typography>
              </CardContent>
              <CardActions>
                <LikeButton
                  likes={JSON.parse(wish?.wishesmeta?.likes)?.length || 0}
                  liked={JSON.parse(wish?.wishesmeta?.likes)?.includes(user.id)}
                  wishid={wish?.databaseId}
                  updateLike={updateLike}
                />
                <ActionsWishes wish={wish} />
              </CardActions>
            </Card>
          )
        )
      })}
    </>
  )
}
