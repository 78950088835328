import React, { useState } from 'react'
import { ButtonGroup, Button } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import StarIcon from '@material-ui/icons/Star'
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined'
import useUser from '../../../hooks/useUser'
import useIsClient from '../../../hooks/useIsClient'
import CongratulationsModal from './CongratulationsModal'
import Tooltip from '@material-ui/core/Tooltip'
import { ShareButtons } from '../../share-buttons'
import useGameConfig from "../../../hooks/useGameConfig";

function Actions ({ mission, onMissionInfoClick }) {
  const { user, addDoneMission, removeDoneMission, addFavorite, removeFavorite } = useUser()
  const [congratsDialogOpen, setCongratsDialogOpen] = useState(false)
  const { gameConfig: { signedInConfig } } = useGameConfig()

  const pathname = typeof window !== 'undefined' ? window.location.pathname : '';

  const favoriteDefault = () => {
    if (!user?.tudus) return false

    return (
      undefined !== user?.tudus.find(task => task.databaseId === mission.databaseId)
    )
  }

  const doneDefault = () => {
    if (!user?.finishedTasks) return false

    return (
      undefined !==
      user?.finishedTasks.find(task => task.databaseId === mission.databaseId)
    )
  }

  const [favorite, setFavorite] = useState(favoriteDefault())
  const [done, setDone] = useState(doneDefault())

  const { isClient } = useIsClient()
  if (!isClient) return null

  const handleMarkMissionAsFavorite = () => {
    const newFavoriteSetting = !favorite
    setFavorite(newFavoriteSetting)
    if (newFavoriteSetting) { addFavorite(mission) } else { removeFavorite(mission)}
  }

  const handleMarkMissionAsDone = () => {
    const newDone = !done
    setDone(newDone)
    if (newDone) {
      setCongratsDialogOpen(true)
      addDoneMission(mission).then((newUser) => {
        if (favorite) {
          removeFavorite(mission, newUser)
          setFavorite(false)
        }
      })
    } else {
      removeDoneMission(mission)
    }
  }

  const handleCongratsDialogClose = () => {
    setCongratsDialogOpen(false)
  }

  return (
    <div className="mission-accordion-buttons">
      <ButtonGroup>
        <Tooltip title="Zusätzliche Info">
          <Button aria-label="info" onClick={(e) => onMissionInfoClick(e, mission)}>
            <InfoIcon className="mission-info-icon"/>
          </Button>
        </Tooltip>
        <Tooltip title="Aufgabe merken?">
          <Button aria-label="TuDu" onClick={() => handleMarkMissionAsFavorite()}>
            {favorite ? (
              <StarIcon className="favorite-icon filled"/>
            ) : (
              <StarBorderOutlinedIcon className="favorite-icon"/>
            )}
          </Button>
        </Tooltip>
        <Tooltip title="Aufgabe erledigt?">
          <Button aria-label="Erledigt" onClick={() => handleMarkMissionAsDone()}>
            {done ? (
              <CheckCircleIcon className="mission-done-icon filled"/>
            ) : (
              <CheckCircleOutlineOutlinedIcon className="mission-done-icon"/>
            )}
          </Button>
        </Tooltip>
        <ShareButtons url={`https://wirkel.anu-hessen.de${pathname}#${mission.slug}`} 
                        title={mission.title} 
                        description={signedInConfig.shareText + " " + mission.title} />
      </ButtonGroup>
      <CongratulationsModal open={congratsDialogOpen} handleClose={handleCongratsDialogClose}
                            earnedPoints={mission.SdgMeta.points}/>
    </div>
  )
}

export default Actions
