import React from "react"
import { BottomNavigation, BottomNavigationAction } from "@material-ui/core"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import { graphql, navigate, useStaticQuery } from "gatsby"
import DashboardIcon from "@material-ui/icons/Dashboard"

function BottomNav({ currentPostSlug }) {
  const {
    allWpSdg: { nodes },
  } = useStaticQuery(graphql`
    {
      allWpSdg(
        sort: {fields: menuOrder, order: ASC}
        filter: {template: {templateName: {ne: "Missions"}}}
      ) {
        nodes {
          slug
        }
      }
    }
  `)


  let nextPostSlug = ""
  for (const [idx, post] of nodes.entries()) {
    if (post && post.slug === currentPostSlug) {
      nextPostSlug = nodes[idx + 1]?.slug
      break
    }
  }
  let previousPostSlug = ""
  for (const [idx, post] of nodes.entries()) {
    if (post && post.slug === currentPostSlug) {
      previousPostSlug = nodes[idx - 1]?.slug
      break
    }
  }


  return (
    <BottomNavigation showLabels className="sdg-footer-nav">
      {previousPostSlug && (
        <BottomNavigationAction
          label="Vorige SDG"
          icon={<ArrowBackIosIcon />}
          onClick={() => navigate("/app/sdg/" + previousPostSlug)}
        />
      )}
      <BottomNavigationAction
        label="Mein Überblick"
        icon={<DashboardIcon />}
        onClick={() => navigate("/app/overview")}
      />
      {nextPostSlug && (
        <BottomNavigationAction
          label="Nächste SDG"
          icon={<ArrowForwardIosIcon />}
          onClick={() => navigate(`/app/sdg/${nextPostSlug}`)}
        />
      )}
    </BottomNavigation>
  )
}

export default BottomNav
