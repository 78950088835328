import React from 'react'

function People ({ points }) {
  const persons = []

  persons.push(
    <svg width="20" height="57" viewBox="0 0 20 57">
      <g transform="translate(-287 -393)">
        <defs>
          <rect x="287" y="393" width="20" height="57"/>
        </defs>
        <clipPath>
          <use width="100%" height="100%" overflow="visible"/>
        </clipPath>
        <path d="m296 393c3.8-1 6.7 0.7 7 4.4 0.3 3.4-2.2 5.8-3.5 6.2-6.5 1.8-12-8.6-3.5-11z" fill="#637886"/>
        <path d="m292 430c-0.9-1.7-2.9-2.3-3.9-3.9 1.1-6.3-1.9-12 0-17 2.1-4.6 7.7-3.3 15-3.4 1.5 1.1 2.8 2.4 3.9 3.9-0.5 7.4 1.9 18-3.9 20 0.1 8.4 2.1 22-7.8 20-5.6-1.6-2.9-13-3.5-19z" clip-path="url(#SVGID_2_)" fill="#637886"/>
      </g>
    </svg>
  )

  persons.push(
    <svg viewBox="0 0 47 59" height="57" >
      <g transform="translate(-273 -392)">
        <defs>
          <rect x="273" y="392" width="47" height="60"/>
        </defs>
        <clipPath>
          <use width="100%" height="100%" overflow="visible"/>
        </clipPath>
        <g fill="#637886">
          <path d="m282 392c9.8-3.3 9.9 10 2.6 11-5.1 0.1-8.2-7.9-2.6-11z"/>
          <path d="m307 392c9.8-3.6 8.7 11 2.1 11-3.8-0.1-8.9-8.1-2.1-11z"/>
          <path d="m295 395c3.4-1 5.9 0.7 6.2 4.4 0.3 3.4-2 5.8-3.1 6.2-5.7 1.8-10-8.6-3.1-11z"/>
          <path d="m291 406c-1.1 2.1-3.4 2.2-4.4 4.9-1.2 3.3-1.5 12-0.5 16 0.4 1.7 2.4 3.1 2.9 5.4 1.1 4.6-0.4 9.3-0.5 13-2.8 3.6-9.1 2.4-11-1.5v-15c-0.6-1.9-3.2-2.6-3.9-5.4-0.8-3.3-0.9-12 0-15 1.8-5.9 12-4.2 17-2.9z" clip-path="url(#SVGID_2_)"/>
          <path d="m318 406c3.6 4.1 4.3 20-1.5 22-0.5 8.4 2.3 20-5.4 20-5.4 0.3-8.3-6.5-6.3-15 0.4-1.8 2.4-3.1 2.9-4.9 0.5-1.9 0.8-14 0-17-0.9-3.1-4.2-3.7-4.9-5.4 3.4-1.7 11-1.1 15-0.5z" clip-path="url(#SVGID_2_)"/>
          <path d="m291 432c-0.9-1.7-2.9-2.3-3.9-3.9 1.1-6.3-1.9-12 0-17 2.1-4.6 7.7-3.3 15-3.4 1.5 1.1 2.8 2.4 3.9 3.9-0.5 7.4 1.9 18-3.9 20 0.1 8.4 2.1 22-7.8 20-5.5-1.5-2.7-12-3.4-19z" clip-path="url(#SVGID_2_)"/>
        </g>
      </g>
    </svg>
  )

  persons.push(
    <svg viewBox="0 0 69 57" height="57" >
      <g transform="translate(-263 -393)">
        <path d="m270 401c7-2 7 8 2 8-4 0.1-6-6-2-8z" fill="#637886"/>
        <path d="m290 401c7-3 7 8 2 8-3 0-7-6-2-8z" fill="#637886"/>
        <defs>
          <rect x="263" y="393" width="69" height="57"/>
        </defs>
        <clipPath>
          <use width="100%" height="100%" overflow="visible"/>
        </clipPath>
        <path d="m280 402c3-0.7 5 0.5 5 3 0.2 2-2 4-2 5-5 1-8-6-2-8z" fill="#637886"/>
        <path d="m276 410c-0.8 2-3 2-3 4-0.9 2-1 9-0.4 12 0.3 1 2 2 2 4 0.8 3-0.3 7-0.4 10-2 3-7 2-8-1v-11c-0.5-1-2-2-3-4-0.6-2-0.7-9 0-11 1-4 9-3 13-2z" fill="#637886"/>
        <g fill="#637886">
          <path d="m297 410c3 3 3 15-1 17-0.4 6 2 15-4 15-4 0.2-6-5-5-11 0.3-1 2-2 2-4 0.4-1 0.6-10 0-12-0.7-2-3-3-4-4 3-1 8-0.9 12-0.4z"/>
          <path d="m277 430c-0.7-1-2-2-3-3 0.8-5-1-9 0-12 2-3 6-2 11-3 1 0.8 2 2 3 3-0.3 6 1 13-3 15 0 6 2 17-6 15-4-1-2-9-3-14z"/>
          <path d="m301 406c8-2 8 8 2 8-4 0.1-6-6-2-8z"/>
          <path d="m323 406c7-3 6 8 2 8-3-0.1-6-6-2-8z"/>
          <path d="m313 408c3-0.7 5 0.5 5 3 0.2 2-2 4-2 5-5 1-8-6-2-8z"/>
        </g>
        <defs>
          <rect x="263" y="393" width="69" height="57"/>
        </defs>
        <clipPath>
          <use width="100%" height="100%" overflow="visible"/>
        </clipPath>
        <g fill="#637886">
          <path d="m310 416c-0.8 2-3 2-3 4-0.9 2-1 9-0.4 12 0.3 1 2 2 2 4 0.8 3-0.3 7-0.4 10-2 3-7 2-8-1v-11c-0.5-1-2-2-3-4-0.6-2-0.7-9 0-11 1-4 9-3 13-2z"/>
          <path d="m330 415c3 3 3 15-1 17-0.4 6 2 15-4 15-4 0.2-6-5-5-11 0.3-1 2-2 2-4 0.4-1 0.6-10 0-12-0.7-2-3-3-4-4 2-1 8-0.8 12-0.4z"/>
          <path d="m310 435c-0.7-1-2-2-3-3 0.8-5-1-9 0-12 2-3 6-2 11-3 1 0.8 2 2 3 3-0.3 6 1 13-3 15 0 6 2 17-6 15-4-1-2-9-3-14z"/>
          <path d="m299 396c8-2 8 8 2 8-4 0.1-6-6-2-8z"/>
          <path d="m284 393c8-3 7 8 2 8-3 0-7-6-2-8z"/>
        </g>
        <path d="m313 398c3-0.7 4 0.5 5 3 0.2 2-2 4-2 5-4 1-8-6-2-8z" fill="#637886"/>
      </g>
    </svg>
  )

  return (<div>{persons[points-1] ?? 2}</div>)
}

export default People